export const avatarData = [
  {
    imgSrc: require("src/assets/profile/avatar/avatar1.png").default,
    active: true,
    avatar: 1,
  },
  {
    imgSrc: require("src/assets/profile/avatar/avatar2.png").default,
    active: true,
    avatar: 2,
  },
  {
    imgSrc: require("src/assets/profile/avatar/avatar3.png").default,
    active: false,
    avatar: 3,
  },
  {
    imgSrc: require("src/assets/profile/avatar/avatar4.png").default,
    active: false,
    avatar: 4,
  },
  {
    imgSrc: require("src/assets/profile/avatar/avatar5.png").default,
    active: false,
    avatar: 5,
  },
  {
    imgSrc: require("src/assets/profile/avatar/avatar6.png").default,
    active: false,
    avatar: 6,
  },
  {
    imgSrc: require("src/assets/profile/avatar/avatar7.png").default,
    active: false,
    avatar: 4,
  },
  {
    imgSrc: require("src/assets/profile/avatar/avatar8.png").default,
    active: false,
    avatar: 5,
  },
  {
    imgSrc: require("src/assets/profile/avatar/avatar9.png").default,
    active: false,
    avatar: 6,
  },
  {
    imgSrc: require("src/assets/profile/avatar/avatar10.png").default,
    active: false,
    avatar: 4,
  },
  {
    imgSrc: require("src/assets/profile/avatar/avatar11.png").default,
    active: false,
    avatar: 5,
  },
  {
    imgSrc: require("src/assets/profile/avatar/avatar12.png").default,
    active: false,
    avatar: 6,
  },
  {
    imgSrc: require("src/assets/profile/avatar/avatar13.png").default,
    active: false,
    avatar: 4,
  },
  {
    imgSrc: require("src/assets/profile/avatar/avatar14.png").default,
    active: false,
    avatar: 5,
  },
  {
    imgSrc: require("src/assets/profile/avatar/avatar15.png").default,
    active: false,
    avatar: 6,
  },
  {
    imgSrc: require("src/assets/profile/avatar/avatar16.png").default,
    active: false,
    avatar: 4,
  },
  {
    imgSrc: require("src/assets/profile/avatar/avatar17.png").default,
    active: false,
    avatar: 5,
  },
  {
    imgSrc: require("src/assets/profile/avatar/avatar18.png").default,
    active: false,
    avatar: 6,
  },
  {
    imgSrc: require("src/assets/profile/avatar/avatar19.png").default,
    active: false,
    avatar: 6,
  },
];
