import React from "react";
import { Wrapper } from "./CustomHeader.styled";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { useGetWalletStatus, useGetInventoryByAddress } from "src/queries";
import { useOutsideClickRef } from "src/services/hooks/useOutsideClickRef";
import { ENVS } from "src/configs/Configs.env";
import { withAccount, withConnect } from "src/hoc";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import PieChartIcon from "@mui/icons-material/PieChart";
import { Mouse } from "../Mouse/Mouse";
import { formatAddressToHuman } from "src/services/utils/format";
import { onMoveAnimation } from "src/services/useDevelopUI";

const CustomHeader = ({
  onOpenConnectModal,
  onOpenPopupConnect,
  onClosePopupConnect,
  onCheckBeforeRequest,
  profile,
  account,
  disconnect,
  onSigninWallet,
  changeChain,
  isConnectedCompatibleChain,
  onOpenPopupRegister,
  token,
}: any) => {
  const history = useHistory();

  const isMobile = window.innerWidth <= 1024 || window.innerHeight < 670;

  const { data: profileData } = profile;
  const { data: walletStatus } = useGetWalletStatus(account);
  const { data: inventoryData } = useGetInventoryByAddress(account);
  const refPopupConnect = React.useRef(null);
  useOutsideClickRef(refPopupConnect, onClosePopupConnect);
  const [isPopupConnectDisplayed, setPopupConnectDisplayed] =
    React.useState(false);

  const togglePopupConnect = () => {
    if (isPopupConnectDisplayed) {
      setPopupConnectDisplayed(false);
      onClosePopupConnect();
    } else {
      setPopupConnectDisplayed(true);
      onOpenPopupConnect();
    }
  };

  const onChangeChain = React.useCallback(
    () => changeChain(ENVS.REACT_APP_BASE_CHAIN_ID),
    [changeChain]
  );

  const [scrollPosition, setSrollPosition] = React.useState(0);
  const handleHeaderAppearanceByScroll = (e: any) => {
    const headerAp = document.querySelector("#app-header-id") as HTMLElement;
    if (scrollPosition > e.currentTarget.scrollY) {
      if (headerAp) {
        headerAp.style.opacity = "1";
      }
    } else if (scrollPosition < e.currentTarget.scrollY) {
      if (headerAp) {
        headerAp.style.opacity = "0";
      }
    }
    setSrollPosition(e.currentTarget.scrollY);
  };
  React.useEffect(() => {
    console.log("scrollPosition", scrollPosition);
    window.addEventListener(
      "scroll",
      (e) => handleHeaderAppearanceByScroll(e),
      {
        passive: true,
      }
    );

    return () =>
      window.removeEventListener("scroll", handleHeaderAppearanceByScroll);
  }, [scrollPosition]);

  return (
    <Wrapper id="app-header-id">
      <div className="header-left">
        <div className="logo" onClick={() => history.push("/")}>
          <img
            className="app-logo"
            src={require("src/assets/header-v2/tuniver-logo.svg").default}
          />
          <img
            className="app-name"
            src={require("src/assets/header-v2/app-name.svg").default}
          />
        </div>

        <div className="menu">
          <Link className="link" to="/binz-campaign">
            BinZ
          </Link>
          <Link className="link" to="/bray-tunipass">
            B Ray
          </Link>
        </div>
      </div>
      <div className="header-right">
        {!token ? (
          <>
            <div
              className="join-now-btn"
              onClick={() => {
                onCheckBeforeRequest();
              }}
            >
              Tham gia ngay
            </div>
            <button
              className="language-dropdown"
              onClick={() => {
                toast.info("Multi language is not available now!");
              }}
            >
              Tiếng Việt
              <img
                className="icon-down"
                src={require("src/assets/header-v2/chevron-down.svg").default}
              />
            </button>
            <div
              className="right-item mobile"
              onClick={() => {
                togglePopupConnect();
              }}
            >
              <img src={require("src/assets/header-v2/wallet.svg").default} />
            </div>
          </>
        ) : (
          <>
            <button
              className="language-dropdown"
              onClick={() => {
                toast.info("Multi language is not available now!");
              }}
            >
              Tiếng Việt
              <img
                className="icon-down"
                src={require("src/assets/header-v2/chevron-down.svg").default}
              />
            </button>
            <Link to="/profile" className="right-item">
              <img
                src={require("src/assets/header-v2/inventory.svg").default}
              />
            </Link>
            <div
              className="right-item"
              onClick={() => {
                togglePopupConnect();
              }}
            >
              <img src={require("src/assets/header-v2/wallet.svg").default} />
            </div>
            <div className="account">
              <div
                className="title"
                onClick={() => {
                  if (isMobile) {
                    onMoveAnimation("nav-bar-id", "moveInOpacity");
                  }
                }}
              >
                <div className="avatar">
                  <img
                    src={require("../../assets/navbar/avatar.png").default}
                    alt="Avatar"
                  />
                </div>
              </div>
              <div className="menu-container">
                <div className="menu">
                  <Link to="/profile" className="menu-item">
                    <img
                      src={
                        require("src/assets/header-v2/dashboard.svg").default
                      }
                    />
                    Dashboard
                  </Link>
                  <Link to="/profile" className="menu-item">
                    <img
                      src={
                        require("src/assets/header-v2/inventory.svg").default
                      }
                    />
                    Inventory
                  </Link>
                  <Link to="/profile" className="menu-item">
                    <img
                      src={require("src/assets/header-v2/profile.svg").default}
                    />
                    Your Profile
                  </Link>
                  <Link onClick={disconnect} to="/" className="menu-item">
                    <img
                      src={require("src/assets/header-v2/logout.svg").default}
                    />
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {/* Connect wallet popup */}
      <div ref={refPopupConnect} id="connect-popup" className="wallet-status">
        <div className="header">
          <span className="title">Your Wallet</span>
          {account && (
            <div className="wallet-info">
              <div className="wallet-info-item">
                <span>{inventoryData?.tunes?.length ?? 0}</span>
                <img
                  src={require("src/assets/tunipassnew/sound.png").default}
                  alt=""
                />
              </div>
              {/* <div className="wallet-info-item">
                        <span>500</span>
                        <img
                          src={require("src/assets/header/ic_eth.png").default}
                          alt=""
                        />
                      </div> */}
              <div className="wallet-info-item">
                <span>{inventoryData?.tuniboxes?.length ?? 0}</span>
                <img
                  src={require("src/assets/header/Tunibox.png").default}
                  alt=""
                />
              </div>
            </div>
          )}
        </div>
        <span className="subtitle">
          You need a crypto wallet to connect with your favorite artists on
          Tuniver. <Link to="/">Learn more.</Link>
        </span>
        {account && (
          <div className="wallet-address">
            <img
              src={require("../../assets/header/logo-network.png").default}
              alt="Logo"
            />
            <div className="address">
              <div className="">
                <span className="network">Binance</span>
              </div>
              <span className="account">{formatAddressToHuman(account)}</span>
            </div>
          </div>
        )}
        {!account ? (
          <div onClick={onOpenConnectModal} className="btn">
            <Mouse id="mouse-chain" />
            Connect Wallet
          </div>
        ) : (
          <>
            {!token && (
              <div
                onClick={
                  walletStatus?.registered
                    ? onSigninWallet
                    : onOpenPopupRegister
                }
                className="btn"
              >
                <Mouse id="mouse-sign" />
                Sign In
              </div>
            )}
            {!isConnectedCompatibleChain && (
              <div onClick={onChangeChain} className="btn">
                <Mouse id="mouse-chain" />
                Change Compatible Network
              </div>
            )}
            <div onClick={disconnect} className="btn-sign">
              Disconnect Wallet
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default withConnect(withAccount(CustomHeader));
