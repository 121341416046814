import React from "react";
import { Wrapper } from "./UploadAvatar.styled";
import CloseIcon from "@mui/icons-material/Close";
import { onMoveAnimation } from "src/services/useDevelopUI";
import AvatarEditor from "react-avatar-editor";
import { handleUploadAvatar } from "src/queries/useProfile";
function DataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

export const UploadAvatar = ({ preview }: any) => {
  const [resAvatar, setResAvatar] = React.useState<any>();

  const [zoom, setZoom] = React.useState<any>("1");
  return (
    <Wrapper className="upload-avatar" id="upload-avatar-popup">
      <label className="">
        <div
          className="icon-close"
          onClick={() => {
            onMoveAnimation("upload-avatar-popup", "moveOutOpacity");
          }}
        >
          <CloseIcon></CloseIcon>
        </div>
        <AvatarEditor
          ref={(e) => {
            setResAvatar(e);
          }}
          image={
            preview ? preview : null
            //  accountInfo?.player?.avatar || AvatarDefault
          }
          width={150}
          height={150}
          border={50}
          scale={0.5 + (zoom - 1) * 0.1}
        />
        <div className="slider-container">
          <div className="slider-track">
            <div className="" style={{}}></div>
          </div>
          <input
            type="range"
            min="1"
            max="21"
            value={zoom}
            className="slider-content"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
          />
        </div>
        <div
          className="btn-primary"
          onClick={async () => {
            const file = DataURIToBlob(resAvatar.getImage().toDataURL());
            const formData = new FormData();
            await formData.append("avatar", file, "image.jpg");
            const response = await handleUploadAvatar(formData);
            console.log(formData);
            console.log(response);
          }}
        >
          Upload
        </div>
      </label>
    </Wrapper>
  );
};
