import { lazy } from "react";
import { IRouteProps } from "src/modules";

export const route = "/binz-campaign";

const BinzCampaignRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import("src/modules/BinzCampaign")),
  name: "BinzCampaign",
};

export default BinzCampaignRoute;
