import styled, { ITheme } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  color: white;
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;

  ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
       position: fixed;
       bottom: 0;
       left: 0;
       width : 100%;
       z-index: 5;
       background: linear-gradient(180deg, rgba(81, 55, 194, 0.2) 0%, rgba(58, 54, 224, 0.2) 100%);
      backdrop-filter: blur(25px);
      border-radius: 20px 20px 0px 0px;
      justify-content: space-between;
      padding: 10px 16px;
    `}
  .tab {
    padding: 16px;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
    transition: all 0.3s;
    margin-right: 5px;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
    svg {
      font-size: 20px;
      margin-right: 8px;
    }
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
       width : 33%;
       max-width: 115px;
    `}
    .title {
      font-family: "Clash Display";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      color: #ffffff;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          display:none;
    `}
    }

    &.active {
      background: linear-gradient(180deg, #5137c2 0%, #3a36e0 100%);
      opacity: 1;
    }
  }
`;
