import React from "react";
import { Wrapper } from "./Navbar.styled";
import withWallet from "src/hoc/withWallet";
import { onMoveAnimation } from "src/services/useDevelopUI";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import { handleShowDisk } from "src/services/useDevelopUI";

let currentPage = 0;
let isProcess = false;
let timeawaiting = 500;
let timeToScroll = window.innerWidth > 640 ? 700 : 650;
const setIsprocess = () => {
  isProcess = true;
};
function doScrolling(elementY, duration) {
  let timeOutScroll = window.innerWidth > 640 ? 1200 : 300;

  let startingY = window.pageYOffset;
  let diff = elementY - startingY;
  let start;
  if (isProcess) return;

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed milliseconds since start of scrolling.
    let time = timestamp - start;
    // Get percent of completion in range [0, 1].
    let percent = Math.min(time / duration, 1);

    window.scrollTo(0, startingY + diff * percent);

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  });
  setTimeout(() => {
    isProcess = false;
  }, timeOutScroll);
}
async function preventKeyDownScroll(e) {
  const maxOffset = 8;
  if (["ArrowUp", "ArrowDown"].includes(e.code)) {
    e.preventDefault();
    e.stopPropagation();
  }
  if (isProcess) return;
  if (e.code === "ArrowDown") {
    if (currentPage < maxOffset && !isProcess) {
      currentPage += 1;

      doScrolling(currentPage * window.innerHeight - 1, timeToScroll);
      await setIsprocess();
      await setTimeout(() => {}, timeawaiting);
    }
  } else if (e.code === "ArrowUp") {
    if (currentPage > 0 && !isProcess) {
      currentPage -= 1;

      doScrolling(currentPage * window.innerHeight - 1, timeToScroll);
      await setIsprocess();
      await setTimeout(() => {}, timeawaiting);
    }
  }
}

async function preventScroll(e) {
  const maxOffset = 8;
  e.preventDefault();
  e.stopPropagation();
  if (isProcess) return;
  if (e.deltaY > 0) {
    if (currentPage < maxOffset && !isProcess) {
      currentPage += 1;

      doScrolling(currentPage * window.innerHeight - 1, timeToScroll);
      await setIsprocess();
      await setTimeout(() => {}, timeawaiting);
    }
  } else if (e.deltaY < 0) {
    if (currentPage > 0 && !isProcess) {
      currentPage -= 1;

      doScrolling(currentPage * window.innerHeight - 1, timeToScroll);
      await setIsprocess();
      await setTimeout(() => {}, timeawaiting);
    }
  }

  return false;
}
let touchstartY;
let touchendY;
function handleStart(e) {
  touchstartY = e.touches[0].clientY;
}

const Navbar = ({ onOpenConnectModal }: any) => {
  const [scrollDown, setScrollDown] = React.useState<any>(false);
  async function handleEnd(e) {
    touchendY = e.changedTouches[0].clientY;
    if (touchendY === touchstartY) return;
    if (isProcess) return;
    const footer = document.querySelector("#app-footer-id");
    if (footer) {
      if (touchendY - touchstartY < -50) {
        if (currentPage < 8 && !isProcess) {
          setScrollDown(!scrollDown);

          currentPage += 1;

          doScrolling(
            (currentPage *
              (document.body.clientHeight -
                footer.getBoundingClientRect().height)) /
              8 -
              1,
            timeToScroll
          );
          await setIsprocess();
          await setTimeout(() => {}, timeawaiting);
        }
      } else if (touchendY - touchstartY > 50) {
        if (currentPage > 0 && !isProcess) {
          setScrollDown(!scrollDown);

          currentPage -= 1;

          doScrolling(
            (currentPage *
              (document.body.clientHeight -
                footer.getBoundingClientRect().height)) /
              8 -
              1,
            timeToScroll
          );
          await setIsprocess();
          await setTimeout(() => {}, timeawaiting);
        }
      }
    }
    setTimeout(() => {
      if (footer)
        doScrolling(
          (currentPage *
            (document.body.clientHeight -
              footer.getBoundingClientRect().height)) /
            8 -
            1,
          80
        );
    }, 700);
  }
  function preventTouch(e) {
    const minValue = 50; // threshold
    if (Math.abs(touchendY - touchstartY) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  }
  const location = useLocation<any>();
  const isMobile = window.innerWidth <= 1024 || window.innerHeight < 670;
  React.useEffect(() => {
    console.log("useEffect call");
    if (isMobile) {
      window.removeEventListener("scroll", handleShowDisk);
      window.removeEventListener("wheel", preventScroll);
      window.removeEventListener("keydown", preventKeyDownScroll);
      window.removeEventListener("keypress", preventKeyDownScroll);
      window.removeEventListener("touchstart", handleStart);
      window.removeEventListener("touchend", handleEnd);
      window.removeEventListener("touchmove", preventTouch);
    } else
      setTimeout(() => {
        if (location?.pathname === "/" || location?.pathname === "") {
          // const progressLeft = document.querySelector(
          //   "#progress-left-id"
          // ) as HTMLElement;
          // const progressBottom = document.querySelector(
          //   "#progress-bottom-id"
          // ) as HTMLElement;
          // const footer = document.querySelector("#app-footer-id");
          // if (progressBottom && footer) {
          //   progressBottom.onclick = function () {
          //     if (!isProcess) {
          //       currentPage += 1;
          //       doScrolling(
          //         (currentPage *
          //           (document.body.clientHeight -
          //             footer.getBoundingClientRect().height)) /
          //           8 -
          //           1,
          //         timeToScroll
          //       );
          //     }
          //   };
          // }
          // if (progressLeft && footer) {
          //   progressLeft.onclick = async function () {
          //     let screenHeight = window.innerHeight;
          //     if (footer) {
          //       screenHeight =
          //         (document.body.clientHeight -
          //           footer.getBoundingClientRect().height) /
          //         8;
          //     }
          //     const scrollY = window.scrollY;
          //     const rate = scrollY / screenHeight;
          //     if (!isProcess) {
          //       if (rate <= 7.01) {
          //         currentPage += 1;
          //         doScrolling(
          //           (currentPage *
          //             (document.body.clientHeight -
          //               footer.getBoundingClientRect().height)) /
          //             8 -
          //             1,
          //           timeToScroll
          //         );
          //         await setIsprocess();
          //         await setTimeout(() => {}, timeawaiting);
          //       } else {
          //         currentPage = 0;
          //         doScrolling(
          //           (currentPage *
          //             (document.body.clientHeight -
          //               footer.getBoundingClientRect().height)) /
          //             8 -
          //             1,
          //           timeToScroll
          //         );
          //         await setIsprocess();
          //         await setTimeout(() => {}, timeawaiting);
          //       }
          //     }
          //   };
          // }
          // doScrolling(currentPage * window.innerHeight - 1, timeToScroll);
          // window.addEventListener("scroll", handleShowDisk);
          // window.addEventListener("touchstart", handleStart, false);
          // window.addEventListener("touchend", handleEnd, false);
          // window.addEventListener("wheel", preventScroll, { passive: false });
          // window.addEventListener("touchmove", preventTouch, {
          //   passive: false,
          // });
          // window.addEventListener("keydown", preventKeyDownScroll, {
          //   passive: false,
          // });
          // window.addEventListener("keypress", preventKeyDownScroll, {
          //   passive: false,
          // });
        } else {
          window.removeEventListener("scroll", handleShowDisk);
          window.removeEventListener("wheel", preventScroll);
          window.removeEventListener("keydown", preventKeyDownScroll);
          window.removeEventListener("keypress", preventKeyDownScroll);
          window.removeEventListener("touchstart", handleStart);
          window.removeEventListener("touchend", handleEnd);
          window.removeEventListener("touchmove", preventTouch);
        }
      }, 1000);

    return () => {
      window.removeEventListener("scroll", handleShowDisk);
      window.removeEventListener("wheel", preventScroll);
      window.removeEventListener("keydown", preventKeyDownScroll);
      window.removeEventListener("keypress", preventKeyDownScroll);
      window.removeEventListener("touchstart", handleStart);
      window.removeEventListener("touchend", handleEnd);
      window.removeEventListener("touchmove", preventTouch);
    };
  }, [location]);
  return (
    <Wrapper>
      <div className="nav-bar" id="nav-bar-id">
        <div className="app-header">
          <div className="container">
            <div className="icon-lefts">
              {/* <img
                src={require("../../assets/header/homewave.png").default}
                alt=""
              /> */}
            </div>
            {/* <div className="btn-center">TuniPass Minting now live</div> */}
            <div className="icon-rights">
              {/* <div className="btn-rights" onClick={onOpenConnectModal}>
                Join us now
              </div> */}

              <img
                src={require("../../assets/header/Group 4813020.png").default}
                alt=""
                onClick={() => {
                  onMoveAnimation("nav-bar-id", "moveOutOpacity");
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="container navlist"
          onClick={() => {
            onMoveAnimation("nav-bar-id", "moveOutOpacity");
          }}
        >
          <div className="nav-link">
            <Link to={"/"}>Home</Link>
          </div>

          <div className="nav-link">
            <Link to={"/bray-tunibox"}>B Ray Tunibox</Link>
          </div>
          <div className="nav-link">
            <Link to={"/bray-tunipass"}>B Ray Tunipass</Link>
          </div>
        </div>
        {/* <div className="nav-media container">
          <div>
            <img
              onClick={() => {
                window.open("https://www.facebook.com/tuniver.io");
              }}
              src={
                require("../../assets/navbar/Facebook - Negative.png").default
              }
              alt=""
            />
            <img
              onClick={() => {
                window.open("https://twitter.com/TuniverOfficial");
              }}
              src={
                require("../../assets/navbar/Twitter - Negative.png").default
              }
              alt=""
            />
            <img
              onClick={() => {
                window.open("https://t.me/tunivervietnam_official");
              }}
              src={require("../../assets/navbar/Subtract.png").default}
              alt=""
            />
            <img
              onClick={() => {
                toast.info("Discord is not available now!");
              }}
              src={require("../../assets/navbar/Vector (1).png").default}
              alt=""
            />
          </div>
        </div> */}
      </div>
    </Wrapper>
  );
};

export default withWallet(Navbar);
