import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 75px;
  width: 100vw;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0px 20px; */
  box-sizing: border-box;
  .content {
    max-width: 1660px;
    width: 100%;
    padding: 0 16px;
  }
`;
