import styled, { ITheme } from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  z-index: 3;
  display: flex;
  flex-direction: column;
  background: rgba(53, 52, 65, 0.5);
  backdrop-filter: blur(50px);
  padding: 20px;
  visibility: hidden;
  opacity: 0;
  .icon-close {
    position: absolute;
    right: 20px;
    cursor: pointer;
  }
  .btn-primary {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Clash Display";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
    margin-top: 47px;
  }
  .slider-container {
    width: 100%;
    position: relative;
    margin-top: 20px;
  }
  .slider-container input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    outline: none;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    background-color: transparent;
    pointer-events: none;
    left: 0;
  }
  .slider-container .slider-track {
    width: 100%;
    height: 2px;
    background-color: #51555a;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 2px;
  }
  .slider-container input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
  }
  .slider-container input[type="range"]::-moz-range-track {
    -moz-appearance: none;
    width: 100%;
    height: 2px;
  }
  .slider-container input[type="range"]::-ms-track {
    appearance: none;
    width: 100%;
    height: 2px;
  }
  .slider-container input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 11.31px; /* Set a specific slider handle width */
    height: 11.31px; /* Slider handle height */
    background: #5b4b37; /* Green background */
    cursor: pointer; /* Cursor on hover */
    transform: rotate(45deg) translateY(-50%);
    position: relative;
    border: solid 3.77px #decfc0;
    outline: solid 1px #ccaa8b;
    pointer-events: auto;
  }
  .slider-container input[type="range"]::-moz-range-thumb {
    -moz-appearance: none;
    width: 11.31px; /* Set a specific slider handle width */
    height: 11.31px; /* Slider handle height */
    background: #5b4b37; /* Green background */
    cursor: pointer; /* Cursor on hover */
    transform: rotate(45deg) translateY(-50%);
    position: relative;
    border: solid 3.77px #decfc0;
    outline: solid 1px #ccaa8b;
    pointer-events: auto;
  }
  .slider-container input[type="range"]::-ms-thumb {
    appearance: none;
    width: 11.31px; /* Set a specific slider handle width */
    height: 11.31px; /* Slider handle height */
    background: #5b4b37; /* Green background */
    cursor: pointer; /* Cursor on hover */
    transform: rotate(45deg) translateY(-50%);
    position: relative;
    border: solid 3.77px #decfc0;
    outline: solid 1px #ccaa8b;
    pointer-events: auto;
  }
`;
