import { lazy } from "react";
import { IRouteProps } from "src/modules";

export const route = "/royaltune-info/:tuneId";

const ProfileRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import("src/modules/RoyalTuneInfo")),
  name: "RoyalTuneInfo",
};

export default ProfileRoute;
