import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { selectToken } from "src/redux/account.selectors";
import http from "src/services/utils/http";
import { ENVS } from "src/configs/Configs.env";

export const handleGetProfile = async () => {
  const response = await http.get("/profile");
  return response;
};

export const handleRequestSendActivationCode = async (email: string) => {
  try {
    const response = await http.get(
      `/profile/request-link-email?email=${email}`
    );
    toast.success(`Code sent to your email ${email}`);
    return response;
  } catch (err: any) {
    toast.error(err?.message || err);
  }
};

export const handleLinkCurrentAccountToEmail = async (
  email: string,
  code: string,
  password: string,
  confirmPassword
) => {
  try {
    const response = await http.put("/profile/link-email", {
      email,
      code,
      password,
      confirmPassword,
    });
    return response;
  } catch (err: any) {
    toast.error(err?.message || err);
  }
};
export const handleRequestLinkWallet = async () => {
  try {
    const response: any = await http.get("/profile/request-link-wallet");
    return response;
  } catch (err: any) {
    toast.error(err?.message || err);
  }
};

export const handleLinkWallet = async (account: any, code: string) => {
  try {
    const response: any = await http.put("/profile/link-wallet", {
      address: account?.toString(),
      chainId: parseInt(ENVS.REACT_APP_BASE_CHAIN_ID),
      code,
    });
    return response;
  } catch (err: any) {
    toast.error(err?.message || err);
  }
};

// Hooks of accounts
export const useGetProfile = () => {
  const token = useSelector(selectToken);
  return useQuery(["useGetProfile.name"], handleGetProfile, {
    enabled: !!token,
    refetchInterval: 3000,
  });
};

export const useGetWalletStatus = (address: any) => {
  return useQuery(
    ["useGetWalletStatus.name"],
    async () => {
      const response: any = await http.get(
        `/auth/wallet-status?address=${address}`
      );
      return response;
    },
    {
      refetchInterval: 3000,
      enabled: !!address,
    }
  );
};

export const useGetUserActivities = (address: any) => {
  return useQuery(
    ["useGetUserActivities.name"],
    async () => {
      const response: any = await http.get(
        `/v2/dashboard/${address}/activity?take=500&pageIndex=0`
      );
      return response;
    },
    {
      refetchInterval: 3000,
    }
  );
};

export const handleChangePassword = async (
  oldPassword: string,
  newPassword: string,
  newPasswordConfirm: string
) => {
  try {
    const response = await http.put("/profile/change-password", {
      oldPassword,
      newPassword,
      newPasswordConfirm,
    });
    return response;
  } catch (err: any) {
    toast.error(err?.message || err);
  }
};

export const handleUploadAvatar = async (avatar: any) => {
  try {
    const response = await http.put("/profile/avatar/upload", {
      avatar,
    });
    return response;
  } catch (err: any) {
    toast.error(err?.message || err);
  }
};
export const handleChangeAvatar = async (url: string) => {
  try {
    const response = await http.put("/profile/avatar", {
      url,
    });
    return response;
  } catch (err: any) {
    toast.error(err?.message || err);
  }
};

export const useGetInventoryByAddress = (address: any) => {
  // const { address }: any = useParams();
  return useQuery(
    ["useGetInventoryByAddress.name"],
    async () => {
      if (!address) {
        return null;
      }
      const response: any = await http.get(`/v2/inventory/${address}`);
      return response;
    },
    {
      // enabled: !!address,
      refetchInterval: 3000,
    }
  );
};
