import styled, { ITheme } from "styled-components";

export const Wrapper = styled.div`
  color: #e7e1d9;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  -moz-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #0b0c0e;
  backdrop-filter: blur(50px);
  top: 0;
  .change-avatar-container {
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    position: relative;
    padding: 25px 39px 25px 39px;
    background: rgba(53, 52, 65, 0.5);
    /* Blur BG 50% */

    backdrop-filter: blur(50px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 30px;
    width: 95%;
    max-width: 710px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToSmall`
        padding: 20px 20px 20px 20px;
       
    `}
    .btn-close {
      position: absolute;
      z-index: 9999999;
      width: 32px;
      height: 32px;
      cursor: pointer;
      top: 40px;
      right: 40px;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToSmall`
        top: 23px;
      right: 23px;
       
    `}
    }
    .title {
      font-family: "Clash Display";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      color: #ffffff;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToSmall`
          font-size: 28px;
       
    `}
    }
    .avatar-action {
      margin-top: 24px;
      display: flex;

      align-items: center;
      gap: 16px;
      justify-content: space-between;
      width: 100%;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToSmall`
       flex-direction: column;
       
    `}
      .btn-secondary,
      .btn-primary {
        width: 50%;
        max-width: 299px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Clash Display";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #ffffff;
        ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToSmall`
        width: 100%;
        max-width: unset;
       
    `}
      }
    }
    .avatar-list {
      margin-top: 64px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 7px;
      max-height: 300px;
      overflow-y: auto;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToSmall`
           margin-top: 44px;
       
    `}
      label {
        input {
          display: none;
        }
      }
      img {
        width: 80px;
        cursor: pointer;
        &.not-allowed {
          cursor: not-allowed;
        }
        &.active {
          background: linear-gradient(99.9deg, #3b67ff 3.81%, #c273f2 46.18%);
        }
      }
    }
  }
`;
