import React, { memo, useCallback } from "react";
import { Wrapper } from "./RegisterForm.styled";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { ArrowBack } from "@mui/icons-material";
import { onMoveAnimation } from "src/services/useDevelopUI";
import { useHistory } from "react-router-dom";
import { withConnect } from "src/hoc";
import { handleLinkWallet, useGetWalletStatus } from "src/queries";
import { useCheckAccountActive } from "src/services/hooks/useWalletProvider";
import { useForm } from "react-hook-form";
import { IStateLogin } from "./RegisterForm.entities";
import { handleLogin } from "src/services/hooks/useAuthenticate";
import { useDispatch } from "react-redux";
import { updateAccount } from "src/redux";
import { isValidEmail } from "src/services/helper/validate";
import { handleRequestLinkWallet } from "src/queries";
import { IStateLinkWallet } from "../AuthenticateForm/AuthenticateForm.entities";
import { toast } from "react-toastify";

const RegisterForm = ({ onSigninWallet, account }: any) => {
  const defaultValuesRequestCode: IStateLogin = {
    email: "",
    password: "",
  };
  const defaultValues: IStateLinkWallet = {
    password: "",
    confirmPassword: "",
    code: "",
  };
  const {
    register,
    trigger,
    getValues,
    formState: { errors },
    reset: reset,
  } = useForm<IStateLinkWallet>({
    mode: "onChange",
    defaultValues,
  });
  const {
    register: registerRequestCode,
    trigger: triggerSignin,
    getValues: getValuesSignin,
    formState: { errors: errorsSignin },
    reset: resetSignin,
  } = useForm<IStateLogin>({
    mode: "onChange",
    defaultValues: defaultValuesRequestCode,
  });
  const dispatch = useDispatch();

  const onSignin = useCallback(async () => {
    console.log("signin");
    const isValid = await triggerSignin();
    if (!isValid) {
      return;
    }
    const data = getValuesSignin();
    const response = await handleLogin(data.email, data.password);
    if (response) {
      await dispatch(updateAccount(response));
      const linkwallet_response = await handleRequestLinkWallet();
      if (linkwallet_response) {
        setStep(2);
      }
      console.log(linkwallet_response);

      console.log("linkaccout", response);
      //   history.push("/");
    }
  }, []);
  const onRequestLinkWallet = async () => {
    const retain = getValues();
    const response = await handleLinkWallet(account, retain.code);
    if (response) {
      toast.info("Link wallet succesfully");
      reset();
      onMoveAnimation("register-option-popup", "moveOutOpacity");
    }
  };
  const { data: walletStatus } = useGetWalletStatus(account);

  const isregistered = walletStatus?.registered;
  const history = useHistory();
  const [step, setStep] = React.useState(0);
  return (
    <Wrapper id="register-option-popup">
      <div
        onClick={() => {
          onMoveAnimation("register-option-popup", "moveOutOpacity");
          resetSignin();
        }}
        className="overlay"
      />
      <div className="form">
        <div className="container-icon">
          <IconButton
            onClick={() => {
              if (step >= 1) {
                setStep(step - 1);
                resetSignin();
              }
            }}
            className={`icon`}
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            className="icon"
            onClick={() => {
              onMoveAnimation("register-option-popup", "moveOutOpacity");
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <span className="title">You are almost there!</span>
        <span className="subtitle">
          Connect your Tuniver account to continue
        </span>
        {step === 0 ? (
          <>
            <LoadingButton
              onClick={() => {
                onSigninWallet();
                onMoveAnimation("register-option-popup", "moveOutOpacity");
              }}
              className="btn-loading-secondary"
              loading={false}
            >
              Sign in with wallet
            </LoadingButton>
            <LoadingButton
              onClick={() => {
                history.push("/authenticate?type=login");
                onMoveAnimation("register-option-popup", "moveOutOpacity");
              }}
              className="btn-loading-secondary"
              loading={false}
            >
              Sign in with Tuniver account
            </LoadingButton>
            <LoadingButton
              onClick={() => {
                history.push("/authenticate");
                onMoveAnimation("register-option-popup", "moveOutOpacity");
              }}
              className="btn-loading-secondary"
              loading={false}
            >
              Create new Tuniver account
            </LoadingButton>
            {!isregistered && (
              <LoadingButton
                loading={false}
                onClick={() => {
                  setStep(1);
                }}
                className="btn-loading-secondary"
              >
                Link to an existing Tuniver account
              </LoadingButton>
            )}
          </>
        ) : step === 1 ? (
          <>
            <input
              placeholder="Email Address"
              disabled={false}
              className={`input ${errorsSignin.email && "error"}`}
              {...registerRequestCode("email", {
                required: true,
                validate: (email) => isValidEmail(email),
              })}
              type="text"
            />
            <input
              placeholder="Password"
              disabled={false}
              className={`input ${errorsSignin.password && "error"}`}
              {...registerRequestCode("password", {
                required: true,
              })}
              type="password"
            />
            <LoadingButton
              onClick={() => {
                onSignin();
                // onMoveAnimation("register-option-popup", "moveOutOpacity");
              }}
              className="btn-loading-secondary"
              loading={false}
            >
              Get code to link wallet
            </LoadingButton>
          </>
        ) : (
          <>
            <input
              placeholder="Code"
              className={`input ${errors.code && "error"}`}
              {...register("code", {
                required: true,
              })}
              type="text"
            />
            <LoadingButton
              onClick={() => {
                onRequestLinkWallet();
                // onMoveAnimation("register-option-popup", "moveOutOpacity");
              }}
              className="btn-loading-secondary"
              loading={false}
            >
              Link now
            </LoadingButton>
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default memo(withConnect(RegisterForm));
