import React from "react";
import { Wrapper } from "./ChangeAvatarPopUp.styled";
import CloseIcon from "@mui/icons-material/Close";
import { onMoveAnimation } from "src/services/useDevelopUI";
import { avatarData } from "./ChangeAvatar.hardcode";
import { UploadAvatar } from "./UploadAvatar";
import { toast } from "react-toastify";
import { handleChangeAvatar } from "src/queries/useProfile";
export const ChangeAvatarPopUp = () => {
  const [preview, setPreview] = React.useState();
  const [avatar, setAvatar] = React.useState(1);
  return (
    <Wrapper className="change-avatar" id="change-avatar-popup">
      <UploadAvatar preview={preview} />
      <div className="change-avatar-container">
        <div
          className="btn-close"
          onClick={() => {
            onMoveAnimation("change-avatar-popup", "moveOutOpacity");
          }}
        >
          <CloseIcon />
        </div>
        <div className="title">Change Avatar</div>
        <div className="avatar-list">
          <label
            className=""
            onClick={() => {
              toast.info("Coming soon");
            }}
          >
            {/* <input
              className=""
              accept="image/*"
              type="file"
              onChange={(e) => {
                let tgt = e?.target || window?.event?.srcElement,
                  files = tgt?.files;
                if (FileReader && files && files?.length) {
                  let fr: any = new FileReader();
                  fr.onload = function () {
                    setPreview(fr.result);
                  };
                  fr.readAsDataURL(files[0]);
                } else {
                }
                onMoveAnimation("upload-avatar-popup", "moveInOpacity");
              }}
            /> */}
            <img
              src={
                require("src/assets/profile/avatar/avatar_blank.png").default
              }
              alt=""
            />
          </label>
          {avatarData?.map((ava, key) => {
            return (
              <div
                key={key}
                onClick={() => {
                  if (ava.active) setAvatar(ava.avatar);
                }}
              >
                <img
                  src={ava.imgSrc}
                  alt=""
                  className={`${ava.avatar === avatar && "active"} ${
                    !ava.active && "not-allowed"
                  } `}
                />
              </div>
            );
          })}
        </div>
        <div className="avatar-action">
          <div
            className="btn-secondary"
            onClick={() => {
              onMoveAnimation("change-avatar-popup", "moveOutOpacity");
            }}
          >
            Later
          </div>
          <div
            className="btn-primary"
            onClick={async () => {
              const response = await handleChangeAvatar(avatar.toString());
              if (response) {
                toast.info("Change avatar successfully");
              } else {
                toast.info("Something went wrong");
              }
              onMoveAnimation("change-avatar-popup", "moveOutOpacity");
            }}
          >
            Change
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
