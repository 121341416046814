import styled from "styled-components";

export const Wrapper = styled.div`
  width: 350px;
  position: sticky;
  top: 170px;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-family: "Clash Display";
  padding: 17px;
  color: white;
  z-index: 3;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    linear-gradient(
      178.91deg,
      #c273f2 -39.88%,
      rgba(34, 30, 231, 0.66) 32.49%,
      rgba(11, 12, 14, 0) 99.06%
    );
  background-blend-mode: overlay, normal;
  border-radius: 30px;
  .avatar-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    align-self: center;
    .avatar-camera {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.75),
        rgba(0, 0, 0, 0.75)
      );
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      cursor: pointer;
      display: none;
      align-items: center;
      justify-content: center;
      &.camera-show {
        display: flex;
      }
      img {
        width: 32px;
      }
    }
  }
  .avatar {
    width: 150px;
    height: 150px;
  }
  .username {
    font-weight: 500;
    font-size: 32px;
    margin-top: 80px;
  }
  .address {
    background: rgba(155, 165, 177, 0.15);
    padding: 15px 30px;
    font-weight: 600;
    font-size: 18px;
    margin-top: 8px;
    margin-bottom: 16px;
    border-radius: 30px;
  }
  .description {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
  .created-at {
    color: #9ba5b1;
    font-weight: 500;
    font-size: 16px;
    margin-top: 60px;
  }
`;
