import styled from "styled-components";

export const Wrapper = styled.div`
  background: rgba(155, 165, 177, 0.15);
  display: flex;
  flex-direction: row;
  color: white;
  height: 56px;
  width: 100%;
  border-radius: 30px;
  padding: 16px 22px;
  input {
    color: white;
    width: 100%;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 16px;
    &::placeholder {
      color: rgba(155, 165, 177, 0.5);
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  .icon {
    margin-right: 10px;
  }
`;
