import { lazy } from "react";
import { IRouteProps } from "src/modules";

export const route = "/profile";

const ProfileRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import("src/modules/Profile")),
  name: "Profile",
};

export default ProfileRoute;
