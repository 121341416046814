import React, { FunctionComponent, useCallback } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/app/store";
import { signInByWallet } from "src/redux";
import {
  useChangeChain,
  useConnectWallet,
  useCheckAccountActive,
  useCheckChain,
  useLogout,
  useCheckChainConnected,
} from "src/services/hooks/useWalletProvider";
import { selectToken } from "src/redux";
import { onMoveAnimation } from "src/services/useDevelopUI";
import { useWallet } from "use-wallet";
import { handleGetProfile } from "src/queries";
// import { useHistory } from "react-router-dom";

export const withConnect =
  (WrappedComponent: FunctionComponent) => (props: any) => {
    const dispatch = useAppDispatch();
    const wallet = useWallet();
    const connect = useConnectWallet();
    const disconnect = useLogout();
    const changeChain = useChangeChain();
    const account = useCheckAccountActive();
    const { data: chain } = useCheckChain();
    const isConnectedCompatibleChain = useCheckChainConnected();
    const token = useSelector(selectToken);
    const onSigninWallet = useCallback(
      () =>
        dispatch(
          signInByWallet({ address: account, provider: wallet?.ethereum })
        ),
      [account, wallet?.ethereum]
    );

    const onOpenConnectModal = useCallback(() => {
      onMoveAnimation("connect-wallet-overlay", "moveInOpacity");

      onClosePopupConnect();
    }, [onMoveAnimation]);

    const onOpenPopupConnect = useCallback(() => {
      const headerAp = document.querySelector("#app-header-id") as HTMLElement;
      if (headerAp) {
        let headerStyle: any = headerAp.style;
        headerStyle.backdropFilter = "none";
      }
      onMoveAnimation("connect-popup", "moveInOpacity");
    }, [onMoveAnimation]);
    const onOpenPopupRegister = useCallback(() => {
      onMoveAnimation("register-option-popup", "moveInOpacity");
    }, [onMoveAnimation]);

    const onClosePopupConnect = useCallback(() => {
      const headerAp = document.querySelector("#app-header-id") as HTMLElement;
      if (headerAp) {
        let headerStyle: any = headerAp.style;
        headerStyle.backdropFilter = "blur(7px)";
      }
      onMoveAnimation("connect-popup", "moveOutOpacity");
    }, [onMoveAnimation]);
    const onCloseConnectModal = useCallback(
      () => onMoveAnimation("connect-wallet-overlay", "moveOutOpacity"),
      [onMoveAnimation]
    );
    const onOpenLinkAccount = useCallback(
      () => onMoveAnimation("link-wallet-form", "moveInOpacity"),
      []
    );
    const onCloseLinkAccount = useCallback(
      () => onMoveAnimation("link-wallet-form", "moveOutOpacity"),
      []
    );

    const onCheckBeforeRequest = useCallback(async () => {
      if (!account) {
        onOpenPopupConnect();
        return false;
      }
      if (!token) {
        onOpenPopupConnect();
        return false;
      }
      const profile: any = await handleGetProfile();

      if (!profile.email) {
        onOpenLinkAccount();
        return false;
      }

      if (!isConnectedCompatibleChain) {
        onOpenPopupConnect();
        return false;
      }
      onClosePopupConnect();
      return true;
    }, [token, isConnectedCompatibleChain]);

    return (
      <WrappedComponent
        {...{
          ...props,
          connect,
          changeChain,
          account,
          chain,
          onCloseConnectModal,
          onOpenConnectModal,
          onOpenPopupConnect,
          onClosePopupConnect,
          onSigninWallet,
          disconnect,
          isConnectedCompatibleChain,
          onCheckBeforeRequest,
          onOpenPopupRegister,
          token,
          onCloseLinkAccount,
        }}
      />
    );
  };

export default withConnect;
