import React from "react";
import { toast } from "react-toastify";
import { Wrapper } from "./Sidebar.styled";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
} from "react-share";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation<any>();

  const usingNewUI = React.useMemo(() => {
    if (location?.pathname === "/binz-campaign") {
      return true;
    }
    return false;
  }, [location]);

  if (location?.pathname === "/" || location?.pathname === "") {
    return <></>;
  }

  return (
    <Wrapper id="sidebar">
      <div className={`container ${usingNewUI && "container-v2"}`}>
        <div className="app-sidebar">
          <div className="sidebar-top">
            <div className={`social ${usingNewUI && "ver2"}`}>
              <div className={`${usingNewUI ? "title-v2" : "title"}`}>
                SHARE
              </div>
              <FacebookShareButton url={window.location.href} className="">
                <img
                  src={
                    require("../../assets/navbar/Facebook - Negative.png")
                      .default
                  }
                  alt=""
                />
              </FacebookShareButton>
              <TwitterShareButton url={window.location.href} className="">
                <img
                  src={
                    require("../../assets/navbar/Twitter - Negative.png")
                      .default
                  }
                  alt=""
                />
              </TwitterShareButton>
              <TelegramShareButton url={window.location.href} className="">
                <img
                  src={require("../../assets/navbar/Subtract.png").default}
                  alt=""
                />
              </TelegramShareButton>

              <img
                onClick={() => {
                  toast.info("Discord is not available now!");
                }}
                src={require("../../assets/navbar/Vector (1).png").default}
                alt=""
              />
            </div>
            {!usingNewUI && (
              <div className="progress">
                {
                  <img
                    src={require("../../assets/sidebar/progress.gif").default}
                    alt=""
                  />
                }
              </div>
            )}
          </div>
          {!usingNewUI && (
            <div className="sidebar-bot">
              <div className="title" id="title-bot-id">
                Scroll down to discover
              </div>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default Sidebar;
