import React from "react";
import { onMoveAnimation } from "src/services/useDevelopUI";
import { Address } from "../Address/Address";
import { Wrapper } from "./CardProfile.styled";
import { ChangeAvatarPopUp } from "./ChangeAvatarPopUp";
import { useGetProfile } from "src/queries";

export const CardProfile = ({ activeTab }: any) => {
  const profile = useGetProfile();
  const { data: profileData }: any = profile;

  // console.log("@profile", profileData);
  return (
    <Wrapper>
      <ChangeAvatarPopUp />

      <div className="avatar-wrapper">
        <img
          className="avatar"
          src={
            require(`../../assets/profile/avatar/avatar${
              profileData?.avatar || "1"
            }.png`).default
          }
          alt="Avatar"
        />
        <div
          className={`avatar-camera ${activeTab === 2 && "camera-show"}`}
          onClick={() => {
            onMoveAnimation("change-avatar-popup", "moveInOpacity");
          }}
        >
          <img
            src={require("src/assets/common_icon/Uploadavata icon.png").default}
            alt=""
          />
        </div>
      </div>
      <span className="username">{profileData?.username || "Tuniver"}</span>
      <div className="address">
        <Address address={profileData?.address || "00..00"} />
      </div>
      {/* TODO: Render real data when API is ready */}
      <span className="description" style={{ display: "none" }}>
        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
        sint. Velit officia consequat duis enim velit mollit. Exercitation
        veniam consequat sunt nostrud amet.
      </span>
      <span className="created-at" style={{ display: "none" }}>
        Member since May 15 2022
      </span>
    </Wrapper>
  );
};
