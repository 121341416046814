import styled, { ITheme } from "styled-components";

export const Wrapper = styled.div`
  transition: all 0.3s;
  z-index: 999;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px 152px;
  background: rgba(11, 12, 14, 0.2);
  backdrop-filter: blur(10px);
  ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
    padding: 16px;
  `}

  .header-left {
    gap: 96px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .logo {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      .app-name {
        margin-left: 16px;
        display: block;
        ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
          display: none;
        `}
      }
    }

    .menu {
      display: flex;
      flex-direction: row;
      gap: 32px;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        display: none;
      `}
      .link {
        font-family: "Clash Display";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #e8e8e8;
        cursor: pointer;
        text-decoration: none;
        border-bottom: 1px solid #e8e8e800;

        &:hover {
          border-bottom: 1px solid #e8e8e8;
        }
      }
    }
  }

  .header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    // .menu-icon {
    //   display: none;
    //   ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
    //     display: block;
    //   `}
    // }
    .join-now-btn {
      background: #c273f2;
      border-radius: 60px;
      padding: 10px 20px;

      font-family: "Clash Display";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        display: none;
      `}
    }
    .language-dropdown {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      border: 1px solid rgba(155, 165, 177, 0.5);
      border-radius: 50px;
      padding: 10px 16px;

      font-family: "Clash Display";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        display: none;
      `}

      .icon-down {
        width: 24px;
        height: 24px;
      }
    }

    .right-item {
      padding: 18px 10px;
      cursor: pointer;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        padding: 6px 10px;
      `}

      &.desktop {
        display: block;
        ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
          display: none;
        `}
      }
      &.mobile {
        display: none;
        ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
          display: block;
        `}
      }
    }

    .account {
      position: relative;
      color: white;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;

      &:hover .title {
        background: rgba(53, 52, 65, 0.5);
        svg {
          transform: rotate(180deg);
        }
      }
      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;
        border-radius: 40px;
        ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          padding: 0px;
        `}
        svg {
          transition: all 0.3s;
        }
        .avatar {
          border-radius: 50%;
          overflow: hidden;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(99.9deg, #3b67ff 3.81%, #c273f2 46.18%),
            linear-gradient(
              334.7deg,
              #7fc1dd 15.23%,
              #7d73f2 17.51%,
              #000000 24.08%
            );
          img {
            width: 38px;
            background-color: black;
            border-radius: 50%;
          }
        }
      }
      &:hover .menu-container {
        opacity: 1;
        visibility: visible;
      }
      .menu-container {
        position: absolute;
        padding-top: 5px;
        top: 52px;
        right: 0;
        width: 224px;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
        display: block;
        ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          width: 185px;
          display: none;
        `}
        .menu {
          width: 100%;
          background: rgba(0, 0, 0, 0.75);
          backdrop-filter: blur(10px);
          border-radius: 5px;
          padding: 16px;
          img {
            margin-right: 12px;
          }
          a {
            color: white;
            text-decoration: none;
            padding: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-family: "Clash Display";
            font-weight: 600;
            font-size: 16px;
            border-radius: 10px;
            transition: all 0.3s;
            svg {
              margin-right: 12px;
            }
            &:hover {
              background: rgba(155, 165, 177, 0.15);
            }
          }
        }
      }
    }
  }
  .wallet-status {
    position: absolute;
    animation: moveOutOpacity 0.3s;
    animation-fill-mode: forwards;
    color: white;
    width: 450px;
    top: 80px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    margin-top: 10px;
    padding: 20px 24px;
    border-radius: 20px 20px 20px 20px;
    right: 0px;

    background: rgba(53, 52, 65, 0.5);
    z-index: 10000;
    backdrop-filter: blur(7px);
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
      top: 60px;
      right: 8px;
      max-width: 90vw;
    `}
    .header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      .wallet-info {
        display: flex;
        align-items: center;
        padding: 5px 7px;
        gap: 5px;
        background: rgba(11, 12, 14, 0.2);
        border-radius: 50px;
      }
      .wallet-info-item {
        display: flex;
        align-items: center;
        padding: 8px 10px;
        gap: 5px;
        background: rgba(11, 12, 14, 0.5);
        border-radius: 40px;
        > span {
          font-family: "Clash Display";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          color: #ffffff;
        }
        > img {
          height: 16px;
        }
      }
    }
    .wallet-address {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 18px;
      img {
        width: 24px;
        margin-right: 10px;
      }
      .address {
        display: flex;
        flex-direction: column;
        span {
          font-family: "Clash Display";
          font-weight: 500;
          font-size: 16px;
        }
        .account {
          color: rgba(155, 165, 177, 1);
          margin-top: 5px;
        }
      }
    }
    .title {
      font-family: "Clash Display";
      font-weight: 600;
      font-size: 24px;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
      font-size: 22px; 
    `}
    }
    .subtitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      display: block;
      margin: 18px 0px;
      a {
        color: rgba(194, 115, 242, 1);
        display: inline;
        font-family: "Poppins";
      }
    }
    .btn-sign {
      width: 100%;
      /* background-color: black; */
      border: 1px solid #c273f2;
      color: #c273f2;
      padding: 12px 20px;
      font-family: "Clash Display";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      border-radius: 20px;

      text-align: center;
      transition: all 0.3s;
      position: relative;
      cursor: pointer;
      &:hover {
        transform: scale(1.02);
      }
      &:active {
        transform: scale(1);
      }
    }
    .btn {
      margin-bottom: 10px;
      padding: 12px 20px;
      width: 100%;
      text-align: center;
      background: linear-gradient(99.9deg, #3b67ff 3.81%, #c273f2 46.18%);
      border-radius: 20px;
      font-family: "Clash Display";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      transition: all 0.2s;
      cursor: pointer;
      position: relative;
      &:hover {
        transform: scale(1.02);
      }
      &:active {
        transform: scale(1);
      }
    }
  }
`;
