import React, { forwardRef, useImperativeHandle } from "react";
import { Wrapper } from "./InputContained.styled";
import { IPropsInputContained, IStateValue } from "./InputContained.entity";
import { useForm } from "react-hook-form";

export const InputContained = forwardRef(
  (
    { Icon, required, placeholder, isPassword, noneSet }: IPropsInputContained,
    ref
  ) => {
    const useFormHandle = useForm<IStateValue>({
      defaultValues: {
        valueOfInput: "",
      },
    });
    const { register } = useFormHandle;
    useImperativeHandle(
      ref,
      () => ({
        useFormHandle,
      }),
      []
    );
    return (
      <Wrapper>
        {Icon && <Icon className="icon" />}
        <input
          {...register("valueOfInput", {
            required: required,
          })}
          type={isPassword ? "password" : "text"}
          placeholder={placeholder}
          autoComplete="off"
          disabled={noneSet}
        />
      </Wrapper>
    );
  }
);
