import React, { useState } from "react";
import { IPropsTabs } from "./Tabs.entity";
import { Wrapper } from "./Tabs.styled";

export const Tabs = ({ tabs, defaultActive }: IPropsTabs) => {
  const [activeTab, setActiveTab] = useState(defaultActive);

  return (
    <Wrapper>
      {tabs.map(({ Icon, handleClick, title, isWorking }, idx) => (
        <div
          onClick={() => {
            setActiveTab(idx);
            handleClick();
          }}
          key={idx}
          className={`tab ${idx === activeTab && "active"} ${
            isWorking && "disabled"
          }`}
        >
          {Icon && <Icon />}
          <span className="title">{title}</span>
        </div>
      ))}
    </Wrapper>
  );
};
